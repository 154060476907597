import { TextField } from '@components'
import Select from '@components/Select'
import useForm, { ValidationRule } from '@utils/hooks/useForm'
import { TCreateAlertsSyslog } from '@utils/api'
import React, { ReactElement } from 'react'
import { Stack } from '@mui/material'

export type TFormAddAlertSyslog = TCreateAlertsSyslog

interface Props {
  form: ReturnType<typeof useForm>
}

export const initialValues: TFormAddAlertSyslog = {
  name: '',
  hostname: '',
  udp_port: 514,
  send_all_alerts: false
}

export const initialValidation: ValidationRule<TFormAddAlertSyslog> = {
  name: (value) => value.length > 0 && /^[ a-zA-Z0-9_\-]{1,255}$/.test(value),
  hostname: (value) => value.length > 0,
  udp_port: (value) => typeof value === 'number' && value > 0 && value < 65536
}

export function FormAddAlertSyslog({ form }: Props): ReactElement {
  return (
    <Stack spacing={3}>
      <TextField
        required
        id="name"
        form={form}
        helperText="Must be unique, no longer then 255 characters and only contain numbers, letters, spaces, hyphens and underscores."
      />
      <TextField required id="hostname" label="Hostname" form={form} />
      <TextField
        id="udp_port"
        helperText="Port values must only be between 1-65535"
        type="number"
        label="UDP Port"
        form={form}
      />
      <Select
        id="send_all_alerts"
        label="Include 'Pass' Results"
        displayEmpty={true}
        renderValue={(value) => `${value}`}
        form={form as any}
        options={[
          { value: true, label: 'True' },
          { value: false, label: 'False' }
        ]}
      />
    </Stack>
  )
}
