import React from 'react'
import useForm from '@hooks/useForm'
import GenericFormPage from '@templates/Generic/Form'
import { Button } from '@components'
import { ApiResponse, createAlertsSyslog } from '@utils/api'
import { Grid } from '@mui/material'
import useApi from '@hooks/useApi'
import {
  FormAddAlertSyslog,
  initialValues,
  initialValidation,
  TFormAddAlertSyslog
} from '@components/Form/FormAddAlertSyslog'

const SettingsAlertsAddEmail = () => {
  const form = useForm<TFormAddAlertSyslog>({
    initialValues,
    initialValidationRules: initialValidation
  })

  const api = useApi<ApiResponse<string>>({
    apiMethod: createAlertsSyslog,
    requestOnMount: false
  })

  return (
    <GenericFormPage
      title="Add Syslog Notification"
      breadcrumbs={[
        {
          title: 'Settings',
          to: '/settings/'
        },
        {
          title: 'Notifications',
          to: '/settings/notifications/'
        },
        {
          title: 'Add Syslog Alert'
        }
      ]}
      form={form as any}
      api={api}
      successMessage="Syslog alert successfully added."
      returnPath="/settings/notifications/"
      feature="syslog_notifications"
      formActions={
        <Grid container spacing={2}>
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              feature="syslog_notifications"
              disabled={api.loading}
            >
              Add Syslog Notification
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="text"
              onClick={() => window !== undefined && window.history.back()}
            >
              {'back'}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <FormAddAlertSyslog form={form as any} />
    </GenericFormPage>
  )
}

export default SettingsAlertsAddEmail
